.static-view {
	margin-top: var(--page-margin-top);

	.vermieten-icons {
		background: #f2f2f2;

		.col-md-3 {
			margin-bottom: 30px;
		}

		.icon {
			i {
				background: var(--color-primary);
				width: 100px;
				height: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 36px;
				border-radius: 100px;
			}
		}

		h4 {
			font-size: 22px;
			margin: 20px 0 15px 0;
			font-weight: 700;
			color: var(--color-primary);
		}
	}
}