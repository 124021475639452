.regionen-v1 {
	margin-top: var(--page-margin-top);

	@media (max-width:767px) {
		overflow: hidden;
	}

	.img-col {
		img {
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
	}

	.regincol {
		@media (max-width:1200px) {
			height: 100%;
		}

		img {
			max-width: 500px;
			width: 100%;

			@media (max-width:1200px) {
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
		}
	}

	.regin-bl {
		margin: 150px 0;
		color: #fff;

		@media (max-width:767px) {
			margin: 100px 0;
		}


		&:nth-child(1) {
			margin-top: 30px;

			@media (max-width:767px) {
				margin-top: 0;
			}
		}

		&:nth-child(odd) {

			.regincol {
				text-align: right;
			}
		}

		&:nth-child(even) {
			.regincol {
				text-align: left;
			}
		}

		.left {
			padding-left: 50px;
			padding-right: 50px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			@media (max-width:767px) {
				padding-top: 50px;
			}

			&:before {
				content: "";
				background-color: var(--color-secondary);
				position: absolute;
				left: 0;
				top: -50px;
				right: -100px;
				bottom: -50px;
				z-index: -1;
			}
		}

		.right {
			padding-left: 50px;
			padding-right: 50px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			@media (max-width:767px) {
				padding-top: 50px;
			}

			h2 {
				text-align: left !important;
				width: 100%;
				color: #fff;
			}

			&:before {
				content: "";
				background-color: var(--color-secondary);
				position: absolute;
				left: -100px;
				top: -50px;
				right: 0;
				bottom: -50px;
				z-index: -1;
			}
		}
	}

	.cover-img {
		margin-bottom: 30px;
		padding-top: 65%;
		position: relative;


		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}