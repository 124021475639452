.themes-v1 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: minmax(150px, auto);
	grid-gap: 1.6em;
	grid-template-areas:
		'img1 img1 img2 img2'
		'img1 img1 img2 img2'
		'img5 img5 img6 img6'
		'img5 img5 img6 img6'
		'img3 img3 img4 img4'
		'img3 img3 img4 img4';


	@media (max-width: 992px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none
	}


	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/demo3-hund.jpg);
	}

	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/meerblick_img.jpg);
	}

	.img3 {
		grid-area: img3;
		//background-image: url(RESOURCE/img/ferienwohnungen_img.jpg);
		background-image: url(RESOURCE/img/slider2.jpg);
	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/tile4.jpg);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/grid-luftbild.jpg);
	}

	.img6 {
		grid-area: img6;
		background-image: url(RESOURCE/img/sauna.jpg);
	}

	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 10px;

		@media (max-width: 992px) {
			min-height: 350px;
		}


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;

		display: flex;
		align-items: center;
		justify-content: center;
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		overflow: hidden;
	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
	}



	@media (min-width: 992px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;
		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;
		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 15%
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 991px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}
}

.index-region-section {
	.row {
		display: block;


		.region-tile-col {
			width: 33.33%;
			padding: 0 15px;
			float: left;

			@media (max-width:767px) {
				width: 100%;
			}

			@media (max-width:992px) {
				.img-box {
					height: 200px;
				}
			}

			&:nth-child(2) {
				@media (min-width:768px) {
					width: 33.33%;
				}
			}

			&:nth-child(3) {
				@media (min-width:768px) {
					width: 33.33%;
				}

				/*	.img-box {
					@media (min-width:768px) {
						height: 670px;
					}

					@media (max-width:992px) {
						height: 430px;
					}

					@media (max-width:767px) {
						height: 200px;
					}

				}*/
			}


		}
	}
}